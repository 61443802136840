import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DAFlexibleButtonComponent } from './components/daflexible-button/daflexible-button.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DAFlexibleButtonComponent
    ],
    declarations: [
      DAFlexibleButtonComponent
    ]
})
export class SharedModule
{
}
