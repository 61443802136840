
<h1 mat-dialog-title style="display:inline-flex; font-weight: 500; font-size: 1rem;">
    <div class="text-2xl font-bold" style="display:flex; vertical-align: center; margin-top: auto; margin-bottom: auto;">Assign Niche Applications</div>
</h1>
<h2>    
    <div class="text-1xl" style="display:flex; vertical-align: center; margin-top: auto; margin-bottom: auto;">Device: <span class="font-semibold ml-2">{{ deviceName }}</span></div>
</h2>
<h2>    
    <div class="text-1xl" style="display:flex; vertical-align: center; margin-top: auto; margin-bottom: auto;">Current Selection:</div>
</h2>
<textarea style="height: 75px; width: 100%;" class="font-semibold" readonly="readOnly">{{ selectedAppNames.join('; ') }}</textarea>

<div class="w-full">

<div>
<mat-dialog-content class="bg-gray-50">

<div class="w-full">

    <kendo-grid
        [kendoGridSelectBy]="'id'"
        [kendoGridBinding]="gridView"
        [(selectedKeys)]="currentSelection"
        (selectionChange)="onSelectionChange($event)"
        kendoGridSelectBy="id"
        [pageSize]="200"
        [pageable]="true"
        [sortable]="true"
        [groupable]="true"
        [reorderable]="true"
        [resizable]="true"
        [height]="500"
        [columnMenu]="{ filter: true }"        
    >

    <ng-template kendoGridToolbarTemplate>
        <kendo-textbox
          [style.width.px]="165"
          placeholder="Search..."
          (valueChange)="onFilter($event)"
        ></kendo-textbox>
    </ng-template>

        <kendo-grid-column field="selected" title="Selected" width="100">
            <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
              <span class="k-checkbox-wrap ng-star-inserted">
                <input class="k-checkbox k-checkbox-md k-rounded-md" 
                ng-reflect-item-index="2"
                aria-label="Select app" type="checkbox"
                (change)="checkboxEdit($event.target.checked, dataItem.id)"
                [checked]="dataItem.selected"
                [disabled]="!dataItem.groupAssignment || !dataItem.groupAssignment.length"
                kendoCheckBox>
            </span>
            </ng-template>
        </kendo-grid-column>        
    
        <kendo-grid-column field="displayName" title="Niche App Name">
        </kendo-grid-column>

        <kendo-grid-column field="type" title="Type">
        </kendo-grid-column>

        <kendo-grid-column field="version" title="Version">
        </kendo-grid-column>
    
        <kendo-grid-column field="groupAssignment" title="Group">
        </kendo-grid-column>
    </kendo-grid>
</div>

</mat-dialog-content>   


<mat-dialog-actions style="justify-content: right;">
    <button mat-stroked-button class="mat-raised-button"(click)="close()">Cancel</button>
    <button mat-stroked-button class="ml-4 mat-raised-button mat-primary"(click)="save()" [disabled]='!canSave'>{{submitActionName}}</button>
</mat-dialog-actions>

</div>

</div>