/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'devices',
        title: 'All Devices',
        type : 'collapsable',
        icon : 'heroicons_outline:server',
        children: [
            {
                id   : 'windows_devices',
                title: 'Windows',
                type : 'basic',
                icon: 'custom_da:windows_icon',
                link : '/all_devices/windows'
            },
            {
                id   : 'ios_devices',
                title: 'iOS/iPadOS',
                type : 'basic',
                icon: 'custom_da:ios_icon',
                link : '/all_devices/ios'
            },
            {
                id   : 'macos_devices',
                title: 'macOS',
                type : 'basic',
                icon: 'custom_da:macos_icon',
                link : '/all_devices/macos'
            },
            {
                id   : 'android_devices',
                title: 'Android',
                type : 'basic',
                icon: 'custom_da:android_icon',
                link : '/all_devices/android'
            },
            {
                id   : 'linux_devices',
                title: 'Linux',
                type : 'basic',
                icon: 'custom_da:linux_icon',
                link : '/all_devices/linux'
            }
        ],
    },
    {
        id   : 'autopilot',
        title: 'Autopilot Devices',
        type : 'basic',
        icon : 'heroicons_outline:server',
        link : '/autopilot_devices'
    },
    {
        id   : 'users',
        title: 'Users',
        type : 'basic',
        icon : 'heroicons_outline:users',
        link : '/users'
    },
    {
        id   : 'newdevice',
        title: 'Register New Device',
        type : 'basic',
        icon : 'heroicons_outline:plus-circle',
        link : '/new_device'
    }
];
/*
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
*/
