import {Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA,  MatDialog,  MatDialogConfig,  MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import { groupCollectionIcon } from '@progress/kendo-svg-icons';
import { process } from "@progress/kendo-data-query";
import { NicheAppCollection, SccmCollection } from '../autopilotlist.types';
import { DataBindingDirective } from '@progress/kendo-angular-grid';

@Component({
    selector: 'course-dialog',
    templateUrl: './nicheapps.dialog.html',
    styleUrls: ['./nicheapps.dialog.css']
})
export class NicheAppsDialogComponent implements OnInit {

    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    public gridView: NicheAppCollection[];

    currentSelection: string[] = [];
    selectedAppNames: string[] = [];
    nicheAppColls: NicheAppCollection[] = [];
    deviceName: any;
    canSave: boolean = false;
    submitActionName: string = '';

    form = this.fb.group({
        selection: ['']
     });

    constructor(private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private dialogRef: MatDialogRef<NicheAppsDialogComponent>
            ) {

        this.currentSelection = data.currentAssignment;
        this.nicheAppColls = <NicheAppCollection[]>data.nicheAppCollections;
        this.deviceName = data.deviceName;

        this.selectedAppNames = this.getAppNames(this.nicheAppColls, this.currentSelection);

        this.submitActionName = data.mode=='install'?'Initiate install':'Save selection';

    }

    getAppNames(appCollection: NicheAppCollection[], currentIds: string[]): string[] {
        // Filter the appCollection to only include apps with ids in currentIds
        const filteredApps = appCollection.filter(app => currentIds.includes(app.id));
            
        // Map the filtered apps to their displayNames
        const appNames = filteredApps.map(app => app.displayName);
        
        return appNames;     
    }

    ngOnInit() {
        this.gridView = this.nicheAppColls;
        for(var app of this.gridView) {
            app.selected = this.isSelectedItem(app.id);
        }

    }

    //public onSelectionChange(event: any): void {
    //    this.canSave = true;
    //    this.currentSelection = event.selectedRows.map(row => row.dataItem.displayName);
    //}
    
    public isSelectedItem(id: string): boolean {
        return this.currentSelection.indexOf(id) >= 0;
    }

    public onSelectionChange(event: any): void {
        this.canSave = true;
    }

    public checkboxEdit(newValue, id) {
        this.canSave = true;
        const matchRow = this.gridView.find((x) => x.id === id);
        if (matchRow) {
          matchRow.selected = (!!newValue);
          if (newValue) {
            this.currentSelection = [...this.currentSelection, id];
          } else {
            this.currentSelection = this.currentSelection.filter((e) => e !== id);
          }

          this.selectedAppNames = this.getAppNames(this.nicheAppColls, this.currentSelection);
        }
    }

    public onFilter(value: Event): void {
        const inputValue = value;
    
        this.gridView = process(this.nicheAppColls, {
          filter: {
            logic: "or",
            filters: [
              {
                field: "displayName",
                operator: "contains",
                value: inputValue,
              },
              {
                field: "type",
                operator: "contains",
                value: inputValue,
              },
              {
                field: "groupAssignment",
                operator: "contains",
                value: inputValue,
              },
              {
                field: "version",
                operator: "contains",
                value: inputValue,
              }
            ],
          },
        }).data;
    
        this.dataBinding.skip = 0;
      }
    

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    close() {

        this.dialogRef.close();

    }

    save() {
        
        this.dialogRef.close({ currentAssignment: this.currentSelection });

    }
}


export function openNicheAppsDialog(dialog: MatDialog, deviceName: string, nicheAppCollections: NicheAppCollection[], currentAssignment: string[], mode='install') {

    const config = new MatDialogConfig();

    config.disableClose = true;
    config.width = "1000px";
    config.minWidth = "900px";
    config.minHeight = "720px";
    config.autoFocus = true;
    config.panelClass = "modal-panel";
    config.backdropClass = "backdrop-modal-panel";

    config.data = {
        deviceName,
        nicheAppCollections,
        currentAssignment,
        mode
    };

    const dialogRef = dialog.open(NicheAppsDialogComponent, config);

    return dialogRef.afterClosed();
}