import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, map, mergeMap, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { Device, DevicePagination } from 'app/modules/admin/devicelist/devicelist.types';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, toODataString } from '@progress/kendo-data-query';import { AutoPilotRowCommand } from '../autopilotlist/autopilotlist.types';
;

export interface MSGraphDataStateWithTab extends MSGraphDataState {
    objectType: 'device' | 'score';
}
export interface MSGraphDataState extends State {
    nextLink?: string;
    total?: number;
    search: string;
    persona: string; 
    platform: string;
}


@Injectable({
    providedIn: 'root'
})
export class DeviceListService extends BehaviorSubject<GridDataResult>
{
    // Private
    private _pagination: BehaviorSubject<DevicePagination | null> = new BehaviorSubject(null);
    private _device: BehaviorSubject<Device | null> = new BehaviorSubject(null);
    private _devices: BehaviorSubject<Device[] | null> = new BehaviorSubject(null);

    currentPage: number = 0;
    currentSize: number = 0;
    pageLinks: string[] = [''];
    isLoading: boolean = false;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
        super({ data: [], total: null });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------


    /**
     * Getter for pagination
     */
    get pagination$(): Observable<DevicePagination>
    {
        return this._pagination.asObservable();
    }

    /**
     * Getter for device
     */
    get device$(): Observable<Device>
    {
        return this._device.asObservable();
    }

    /**
     * Getter for devices
     */
    get devices$(): Observable<Device[]>
    {
        return this._devices.asObservable();
    }


    loadRowCommands(): Observable<AutoPilotRowCommand[]>
    {
        return this._httpClient.get<AutoPilotRowCommand[]>('/api/devices/commands')
            .pipe(
                tap((response: AutoPilotRowCommand[]) => {
                    return response;
                })
            );
    }

    execCommand(deviceId: string, commandId: string): Observable<Device> {
        return this._httpClient.post<Device>('/api/device/command', { "commandId": commandId, "deviceId": deviceId}).pipe(
            tap((device) => {
                // Update the device
                this._device.next(device);
                return of(device);
            })
        );
    }    

    /**
     * Get Devices
     *
     *
     * @param size
     * @param sort
     * @param order
     * @param search
     * @param persona
     */
    getDevices(size: number = 10, sort: string = 'deviceName', order: 'asc' | 'desc' | '' = '', search: string = '', persona: string = ''):
        Observable<{ devices: Device[]; nextLink: string; }>
    {
        this.currentPage = 0;
        this.currentSize = size;
        this.pageLinks = ['/'];

        return this._httpClient.get<{ devices: Device[]; nextLink: string; }>('/api/devices', {
            params: {
                size,
                sort,
                order,
                search: search||'',
                persona: persona||''
            }
        }).pipe(
            tap((response) => {
                this._pagination.next( { page: 0, length: response.devices.length + (response.nextLink ? size : 0), size: size });
                this._devices.next(response.devices);
                if(response.nextLink)
                    this.pageLinks.push(response.nextLink);
            })
        );
    }

    hasNextDevices(): boolean
    {
        return this.pageLinks.length>this.currentPage+1 && !!this.pageLinks[this.currentPage + 1];
    }

    hasPreviousDevices(): boolean
    {
        return this.currentPage > 0 && !!this.pageLinks[this.currentPage - 1];
    }


    getNextDevices(size: number = 10, sort: string = null, order: 'asc' | 'desc' | '' = '', search: string = null, persona: string = null): 
        Observable<{ devices: Device[]; nextLink: string; }>
    {
        this.currentPage = this.currentPage + 1;

        return this._httpClient.get<{ devices: Device[]; nextLink: string; }>('/api/devices', {
            params: {
                url: this.pageLinks[this.currentPage]
            }
        }).pipe(
            tap((response) => {
                this._pagination.next({ page: this.currentPage, length: (this.currentPage) * size + response.devices.length + (response.nextLink ? size+1 : 0), size: size });
                this._devices.next(response.devices);
                if(response.nextLink)
                    this.pageLinks.push(response.nextLink);
            })
        );
    }

    getPrevDevices(size: number = 10, sort: string = null, order: 'asc' | 'desc' | '' = '', search: string = null, persona: string = null):
        Observable<{ devices: Device[]; nextLink: string; }>
    {
        this.currentPage = this.currentPage - 1;

        if(this.currentPage == 0) {
            return this.getDevices(size, sort, order, search, persona);
        }

        return this._httpClient.get<{ devices: Device[]; nextLink: string; }>('/api/devices', {
            params: {
                url: this.pageLinks[this.currentPage]
            }
        }).pipe(
            tap((response) => {
                this._pagination.next({ page: this.currentPage, length: (this.currentPage + 1) * size + response.devices.length + (response.nextLink ? size : 0), size: size });
                this._devices.next(response.devices);
            })
        );        
    }


    public queryPersonas(): Observable<string[]> {
        return this._httpClient.get<string[]>('/api/personas');
    }

    public queryUserExperience(deviceId: string): Observable<any> {
        return this._httpClient.get<any>('/api/devices/' + deviceId + '/userexperience');
    }

    public loadDeviceInfo(deviceId: string): Observable<any> {
        return this._httpClient.get<any>('/api/devices/' + deviceId + '/info');
    }

    public queryDeviceData(state: MSGraphDataStateWithTab): Observable<GridDataResult> {

        let t = this.fetch(state);
        t.subscribe((x) => super.next(x));
        return t;
    }

    protected fetch(state: MSGraphDataStateWithTab): Observable<GridDataResult> {

        this.isLoading = true;

        console.log(state.objectType)
        const apiEndpointsMapping = {
            'device': '/api/devices',
            'score': '/api/device_scores'
        }

        return this._httpClient.get<{ devices: Device[]; nextLink: string; }>(apiEndpointsMapping[state.objectType], {
            params: {
                size: state.take,
                skip: state.skip,
                sort: state.sort[0].field,
                order: state.sort[0].dir,
                search: state.search||'',
                persona: state.persona,
                platform: state.platform
            }
        }).pipe(
            map(
                (response: any) => {
                    state.nextLink = response.nextLink;
                    state.total = response.total;
                    return <GridDataResult>{
                        data: response.devices,
                        total: state.total
                    }
                }
            ),
            tap(() => (this.isLoading = false))
        );
        
        /*
        else
        {
            return this._httpClient.get<{ devices: Device[]; nextLink: string; }>('/api/devices', {
                params: {
                    url: state.nextLink
                }
            }).pipe(
                map(
                    (response: any) => {
                        state.nextLink = response.nextLink;
                        return <GridDataResult>{
                            data: response.devices,
                            total: state.total,
                        }
                    }
                ),
                tap(() => (this.isLoading = false))
            );
        }
        */
    }

}
