<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <img
                    style="width: 12rem;"
                    src="assets/images/logo/logo-text-on-dark.png"
                    alt="Logo image">
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">

    <!-- Header -->
    <div class="relative flex flex-col flex-0 justify-center w-full overflow-hidden z-49 shadow dark:shadow-none print:hidden">
        <!-- Top bar -->
        <div class="relative flex flex-auto justify-start w-full px-4 md:px-8 bg-gray-300 dark:bg-gray-300">
            <div class="flex items-center w-full h-16 sm:h-20">
                <!-- Logo -->
                <ng-container *ngIf="!isScreenSmall">
                    <div class="flex items-center">
                        <img
                            style="width: 10rem;"
                            src="assets/images/logo/redxcarbon.png"
                            alt="Logo image">                       
                    </div>
                </ng-container>
                <!-- Navigation toggle button -->
                <ng-container *ngIf="isScreenSmall">
                    <button
                        mat-icon-button
                        (click)="toggleNavigation('mainNavigation')">
                        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                    </button>
                </ng-container>
                <!-- Components -->
                <div class="flex items-center pl-4 space-x-0.5 sm:space-x-2">
                    <!-- fuse-fullscreen class="hidden md:block"></fuse-fullscreen -->
                    <fuse-horizontal-navigation
                    class="-mx-4"
                    [name]="'mainNavigation'"
                    [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
                </div>
                <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
                    <user></user>
                </div>
            </div>
        </div>
        <!-- Bottom bar -->
        <ng-container *ngIf="!isScreenSmall">
            <!--div class="flex flex-auto justify-center px-4 md:px-8 bg-card dark:bg-gray-700">
                <div class="relative flex items-center w-full max-w-360 h-16">
                </div>
            </div -->
        </ng-container>
    </div>

    <!-- Content  sm:p-6 md:p-8 margin-bottom 2.5rem because of footer is h-6 sm:h-10 -->
    <div class="flex flex-auto justify-center w-full" style="margin-bottom: 2.5rem;">
        <div class="flex flex-col flex-auto w-full sm:shadow-lg sm:rounded-lg bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <div class=" flex flex-0 justify-center w-full px-6 h-6 sm:h-10 md:px-8 z-49 border-t bg-card print:hidden" style="position: fixed; bottom: 0px;">
        <div class="flex items-center w-full max-w-360">
            <span class="font-medium text-secondary">Powered by Red X Carbon &copy; {{currentYear}}</span>
        </div>
    </div>

</div>
