<button
    mat-flat-button
    [color]="color"
    [disabled]="isDisabled"
    (click)="handleClick($event)"
    class="flexible-button"
>

<!-- Projected content (button label, icon...) -->

<ng-content></ng-content>

</button>