import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, filter, map, mergeMap, Observable, of, switchMap, take, tap, throwError, catchError } from 'rxjs';
import { Device, DeviceStatus, DevicePagination, AutoPilotRowCommand, SccmCollection, NicheAppCollection } from 'app/modules/admin/autopilotlist/autopilotlist.types';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, toODataString } from '@progress/kendo-data-query';;


export interface AutopilotDataState extends State {
    nextLink?: string;
    total?: number;
    search: string;
    status: string;
}

@Injectable({
    providedIn: 'root'
})
export class AutoPilotListService extends BehaviorSubject<GridDataResult>
{
    // Private
    private _statuses: BehaviorSubject<DeviceStatus[] | null> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<DevicePagination | null> = new BehaviorSubject(null);
    private _device: BehaviorSubject<Device | null> = new BehaviorSubject(null);
    private _devices: BehaviorSubject<Device[] | null> = new BehaviorSubject(null);

    currentPage: number = 0;
    currentSize: number = 0;
    pageLinks: string[] = [''];
    isLoading: boolean = false;


    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
        super({ data: [], total: null });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------


    /**
     * Getter for statuses
     */
    get categories$(): Observable<DeviceStatus[]>
    {
        return this._statuses.asObservable();
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<DevicePagination>
    {
        return this._pagination.asObservable();
    }

    /**
     * Getter for device
     */
    get device$(): Observable<Device>
    {
        return this._device.asObservable();
    }

    /**
     * Getter for devices
     */
    get devices$(): Observable<Device[]>
    {
        return this._devices.asObservable();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get statuses
     */
    getStatuses(): Observable<DeviceStatus[]>
    {
        return this._httpClient.get<DeviceStatus[]>('/api/autopilot/statuses').pipe(
            tap((statuses) => {
                this._statuses.next(statuses);
            })
        );
    }

    /**
     * Get Devices
     *
     *
     * @param page
     * @param size
     * @param sort
     * @param order
     * @param search
     */
    getDevices(page: number = 0, size: number = 10, sort: string = 'serialNumber', order: 'asc' | 'desc' | '' = 'asc', search: string = ''):
        Observable<{ pagination: DevicePagination; devices: Device[] }>
    {
        return this._httpClient.get<{ pagination: DevicePagination; devices: Device[] }>('/api/autopilot/info', {
            params: {
                page: '' + page,
                size: '' + size,
                sort,
                order,
                search
            }
        }).pipe(
            tap((response) => {
                this._pagination.next(response.pagination);
                this._devices.next(response.devices);
            })
        );
    }

    getSccmCollections(): Observable<SccmCollection[]>
    {
        return this._httpClient.get<any>('/api/sccmcollections');
    }

    getNicheAppCollections(platform: string): Observable<NicheAppCollection[]>
    {
        return this._httpClient.get<any>('/api/nicheappcollections/' + platform);
    }


    getAssignmentInfo(userId: string, upn: string): Observable<any>
    {
        return this._httpClient.get<any>('/api/autopilot/' + userId + '/' + upn + '/assignmentinfo');
    }


    getRefreshProvisioningInfo(userId: string, upn: string): Observable<any>
    {
        return this._httpClient.get<any>('/api/autopilot/' + userId + '/' + upn + '/refreshinfo');
    }

    getRefreshDeviceInfo(deviceName: string): Observable<any>
    {
        return this._httpClient.get<any>('/api/device/' + deviceName + '/provisioning');
    }

    getDeviceNicheApps(deviceId: string): Observable<any>
    {
        return this._httpClient.get<any>('/api/nicheapps?deviceId=' + deviceId);
    }


    setReadyToShip(deviceId: string, user: any): Observable<any>
    {
        return this._httpClient.post<any>('/api/autopilot/' + deviceId + '/readytoship', { 'user': user })
        .pipe(
            catchError((err) => {
                return err;
            }),
            tap((response: any) => {
                return response;
            }),
            switchMap((result) => {
                return this.pipe(take(1));
            })
        ).pipe(
            tap((gridData) => {
                let devices = this._devices.getValue();
                let device = devices.find(d => d.id === deviceId);
                device.readyToShipEpoch = Date.now();
                device.assignedTo = user;
                this.next(gridData);
        }));
    }

    /**
     * Get product by id
     */
    getDeviceById(id: string): Observable<Device>
    {
        return this._httpClient.get<Device>('/api/autopilot/' + id + '/info').pipe(
            tap((device) => {
                // Update the device
                this._device.next(device);
                return of(device);
            })
        );

        /*
        return this._devices.pipe(
            take(1),
            map((devices) => {

                // Find the device
                const device = devices.find(item => item.id === id) || null;

                // Update the device
                this._device.next(device);

                // Return the device
                return device;
            }),
            switchMap((device) => {

                if ( !device )
                {
                    return throwError('Could not found device with id of ' + id + '!');
                }

                return of(device);
            })
        );
        */
    }


    execCommand(deviceId: string, commandId: string): Observable<Device> {
        return this._httpClient.post<Device>('/api/device/command', { "commandId": commandId, "deviceId": deviceId}).pipe(
            tap((device) => {
                // Update the device
                this._device.next(device);
                return of(device);
            })
        );
    }
    

    saveDeviceProps(device: any): Observable<any>
    {
        let savedStatus = DeviceStatus.UNKNOWN;

        return this._devices.pipe(
            take(1),
            tap(devices => {
                const deviceRow = devices.find(item => item.id === device.id) || null;
                savedStatus = deviceRow.deviceStatus;
                deviceRow.deviceStatus = DeviceStatus.UPDATING;
                this._devices.next(devices);
            }),
            switchMap(() => {
                return this._httpClient.post('/api/autopilot/save_props', device).pipe(
                    tap((response: any) => {
                        // todo: any additional post-save processing
                        return response;
                    }),
                    switchMap(() => {
                        return this._devices.pipe(take(1));
                    }),
                    tap(devices => {
                        const deviceRow = devices.find(item => item.id === device.id) || null;
                        deviceRow.po = device.po;
                        deviceRow.assetTag = device.assetTag;
                        deviceRow.deviceStatus = savedStatus;
                        this._devices.next(devices);
                    })
                );
            })
        );
    }

    assignDevice(deviceId: string, user: any, personaName: string, option: string): Observable<any>
    {
        // need to handle error http responses from this._httpClient.post
        return this._httpClient.post('/api/autopilot/assign', { "deviceId": deviceId, "userId": user.id, "upn": user.userPrincipalName||user.upn, "displayName": user.displayName, "option": option })
        .pipe(
            catchError((err) => {
                return err;
            }),
            tap((response: any) => {
                return response;
            }),
            switchMap((result) => {
                return this.pipe(take(1));
            })
        ).pipe(
            tap(gridData => {
                const device = gridData.data.find(item => item.id === deviceId) || null;
                device.assignedTo.displayName = user.displayName;
                device.assignedTo.upn = user.userPrincipalName;
                device.assignedTo.personaName = personaName,
                this.next(gridData);
            }),
            catchError((err) => {
                return err;
            })            
        );
    } 


    refreshDevice(deviceToRefresh: string, deviceId: string, user: any, personaName: string, option: string, nicheApps: string[], 
        sccmCollection: string /*, sccmAdminAccount: string */)
    {
        // need to handle error http responses from this._httpClient.post
        return this._httpClient.post('/api/device/' + deviceToRefresh + '/provisioning/refresh', 
            { "targetDeviceId": deviceId, "primaryUser": user, "nicheApps": nicheApps, "option": option,
                "sccmCollection": sccmCollection /*, "sccmAdminAccount": sccmAdminAccount*/
            })
        .pipe(
            catchError((err) => {
                return err;
            }),
            tap((response: any) => {
                return response;
            }),
            switchMap((result) => {
                return this.pipe(take(1));
            })
        ).pipe(
            tap(gridData => {
                const device = gridData.data.find(item => item.id === deviceId) || null;
                device.assignedTo.displayName = user.displayName;
                device.assignedTo.upn = user.userPrincipalName;
                device.assignedTo.personaName = personaName,
                this.next(gridData);
            }),
            catchError((err) => {
                return err;
            })            
        );
    }

    assignNicheApps(deviceId: string, nicheApps: string[]): Observable<any>
    {
        return this._httpClient.post('/api/nicheapps', { "deviceId": deviceId, "nicheApps": nicheApps }).pipe(
            tap((response: any) => {
                return response;
            }),
            switchMap((result) => {
                return this.pipe(take(1));
            })
        ).pipe(
            tap(gridData => {
                const device = gridData.data.find(item => item.id === deviceId) || null;
                device.nicheApps = nicheApps;
                device.nicheAppAssignmentStatus = 'In progress';
                this.next(gridData);
            }),
            catchError((err) => {
                return err;
            })            
        );
    }


    queryNicheAppAssignmentStatus(deviceId): Observable<boolean>
    {
        let nicheApps = [];
        let status = '';
        let finished = false;
        let msg = '';
    
        // this method must return raw_status
        return this._httpClient.get('/api/nicheapps?deviceId=' + deviceId).pipe(
            tap((response: any) => {
                return response;
            }),
            switchMap((result) => {
                nicheApps = result.assignment.sccmCollNames;
                status = result.status; 
                msg = result.assignment.statusMessage;
                finished = result.assignment.status !== 'in_progress';
                return this.pipe(take(1));
            })
        ).pipe(
            tap(gridData => {
                const device = gridData.data.find(item => item.id === deviceId) || null;
                device.nicheApps = nicheApps;
                device.nicheAppAssignmentStatus = status;
                device.nicheAppAssignmentMessage = msg;
                this.next(gridData);
            }),
            switchMap(() => {
                return of(finished);
            }),
            catchError((err) => {
                return throwError(err);
            })            
        );
    }



    syncDevice(deviceId: string): Observable<Device>
    {
        return this._httpClient.post<Device>('/api/autopilot/' + deviceId + '/sync', { });            
    } 

    markAsReady(deviceId: string): Observable<Device>
    {
        return this._httpClient.post<Device>('/api/autopilot/' + deviceId + '/markasready', { });            
    } 


    syncDevices(): Observable<any>
    {
        return this._httpClient.post('/api/autopilot/sync', {})
            .pipe(
                tap((response: any) => {
                    return response;
                })
            );

    } 

    loadRowCommands(): Observable<AutoPilotRowCommand[]>
    {
        return this._httpClient.get<AutoPilotRowCommand[]>('/api/autopilot/commands')
            .pipe(
                tap((response: AutoPilotRowCommand[]) => {
                    return response;
                })
            );
    }

    public queryDeviceData(state: AutopilotDataState): Observable<GridDataResult> {

        let t = this.fetch(state);
        t.subscribe((x) => super.next(x));
        return t;
    }

    public refresh() {
        super.next(this.getValue());
    }

    protected fetch(state: AutopilotDataState): Observable<GridDataResult> {

        this.isLoading = true;

        return this._httpClient.get<{ devices: Device[]; nextLink: string; }>('/api/autopilot/info', {
            params: {
                size: state.take,
                skip: state.skip,
                sort: state.sort[0].dir ? state.sort[0].field : '',
                order: state.sort[0].dir||'',
                search: state.search||'',
                status: state.status||''
            }
        }).pipe(
            map(
                (response: any) => {
                    state.nextLink = response.nextLink;
                    state.total = response.total;
                    this._devices.next(response.devices);
                    return <GridDataResult>{
                        data: response.devices,
                        total: state.total
                    }
                }
            ),
            tap(() => (this.isLoading = false))
        );
    }
}
