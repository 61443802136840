<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">

    <!-- Header -->
    <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-4 px-4 md:px-8 border-b">
        <!-- Title -->
            <div class="text-4xl tracking-tight leading-snug"><span class="font-extrabold">All devices</span> <span class="text-3xl leading-[32px] font-medium text-neutral-600"> | {{platformTitle}}</span> 
                <mat-icon
                matPrefix
                class="ml-2"
                [svgIcon]="'custom_da:'+state.platform+'_icon'"></mat-icon>
            </div>
        <!-- Actions -->
        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search -->
            <mat-form-field
                class="fuse-mat-dense fuse-mat-rounded min-w-64 w-12" style="width: 350px !important"
                [subscriptSizing]="'dynamic'">
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input
                    matInput
                    [formControl]="searchInputControl"
                    [autocomplete]="'off'"
                    [placeholder]="'Search devices'">
            </mat-form-field>
            <mat-form-field appearance="fill" class="responsive-width ml-2" style="width: 350px !important">
                <mat-label>Persona</mat-label>
                <mat-select [formControl]="personaControl" multiple (selectionChange)="onPersonaSelectionChange($event);">
                    <mat-option *ngFor="let persona of personaList$ | async" [value]="persona">{{persona}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <!-- Tab Selector -->
        <kendo-tabstrip (tabSelect)="onTabSelect($event)">
            <kendo-tabstrip-tab title="DEVICES" [selected]="true">
                <ng-template kendoTabContent>

                    <!-- Kendo grid -->
                    <kendo-grid
                        [data]="griDataView | async"
                        [loading]="isGridLoading"
                        [pageSize]="state.take"
                        [skip]="state.skip"
                        scrollable="virtual"
                        [rowHeight]="36"
                        [reorderable]="true"
                        [resizable]="true"
                        [sort]="state.sort"
                        [sortable]="true"
                        kendoGridSelectBy="id"
                        [columnMenu]="{ filter: false }"
                        (pageChange)="onPageChange($event)"
                        (dataStateChange)="onDataStateChange($event)"
                    >
                        <kendo-grid-column field="deviceName" title="Device Name" [width]="300" [sortable]="true">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div
                                class="device-image"
                                [ngStyle]="{ 'background-image': getDeviceImage(dataItem.manufacturer) }"
                                ></div>
                                <div class="device-name">{{ dataItem.deviceName }}</div>
                            </ng-template>        
                        </kendo-grid-column>
                        <kendo-grid-column field="serialNumber" title="Serial Number" [width]="350" [sortable]="true"></kendo-grid-column>
                        <kendo-grid-column field="complianceState" title="Compliance State" [width]="200">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="truncate">
                                    {{ DeviceFieldMappings.ComplianceState[dataItem.complianceState ]}}
                                </div>        
                            </ng-template>        
                        </kendo-grid-column>
                        <kendo-grid-column field="deviceEnrollmentType" title="Enrollment Type" [width]="200">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="truncate">
                                    {{ DeviceFieldMappings.EnrollmentType[dataItem.deviceEnrollmentType]}}
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="userPrincipalName" title="Primary User UPN" [sortable]="true"></kendo-grid-column>
                        <kendo-grid-command-column title="Actions" [width]="200">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="flex">
                                <div class="action-buttons">
                                    <button
                                    class="min-w-10 h-7 px-2 leading-6"
                                    style="margin-left: 5px;"
                                    mat-stroked-button
                                    [matMenuTriggerFor]="summaryMenu">
                                    <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:arrow_drop_down'"></mat-icon>
                                </button>
                                <mat-menu #summaryMenu="matMenu">
                                    <!-- prepend icon for each menu button -->
                                    <button mat-menu-item (click)="onUserExp(dataItem.id)">
                                        <mat-icon svgIcon="mat_solid:score"></mat-icon><span>User Experience</span>
                                    </button>
                                    <mat-divider></mat-divider>
                                    <ng-container *ngFor="let cmd of rowCommands; trackBy: trackByFn">
                                        <button mat-menu-item (click)="onRowCommand(cmd, dataItem)">
                                            <mat-icon svgIcon="{{cmd.iconName}}"></mat-icon><span>{{cmd.displayName}}</span>
                                        </button>
                                    </ng-container>    
                                </mat-menu>                    
                                </div>

                                <ng-container *ngFor="let lbl of dataItem.labels">
                                    <mat-icon class="icon-size-5" [svgIcon]="lbl.icon" style="margin: auto;" matTooltip="{{ lbl.label }} pending"></mat-icon>
                                </ng-container>

                                </div>
                            </ng-template>
                        </kendo-grid-command-column>
                    </kendo-grid>

                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="HEALTH">
                <ng-template kendoTabContent>
                     <!-- Score grid -->
                     <kendo-grid
                     [data]="griDataView | async"
                     [loading]="isGridLoading"
                     [pageSize]="state.take"
                     [skip]="state.skip"
                     scrollable="virtual"
                     [rowHeight]="36"
                     [reorderable]="true"
                     [resizable]="true"
                     [sort]="state.sort"
                     [sortable]="true"
                     kendoGridSelectBy="id"
                     [columnMenu]="{ filter: false }"
                     (pageChange)="onPageChange($event)"
                     (dataStateChange)="onDataStateChange($event)"
                 >
                     <kendo-grid-checkbox-column
                         [width]="45"
                         [headerClass]="{ 'text-center': true }"
                         [class]="{ 'text-center': true }"
                         [resizable]="false"
                         [columnMenu]="false"
                         [showSelectAll]="true"
                     ></kendo-grid-checkbox-column>
                     <kendo-grid-column field="deviceName" title="Device Name" [width]="300" [sortable]="true">
                         <ng-template kendoGridCellTemplate let-dataItem>
                             <div
                             class="device-image"
                             [ngStyle]="{ 'background-image': getDeviceImage(dataItem.manufacturer) }"
                             ></div>
                             <div class="device-name">{{ dataItem.deviceName }}</div>
                         </ng-template>        
                     </kendo-grid-column>
                     <kendo-grid-column field="endpointAnalyticsScore" title="Endpoint Analytics Score" [sortable]="true">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div>{{formatScoreColumn(dataItem.endpointAnalyticsScore)}}</div>
                        </ng-template>
                     </kendo-grid-column>
                     <kendo-grid-column field="startupPerformanceScore" title="Startup Performance Score" [sortable]="true">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div>{{formatScoreColumn(dataItem.startupPerformanceScore)}}</div>
                        </ng-template>
                     </kendo-grid-column>
                     <kendo-grid-column field="appReliabilityScore" title="App Reliability Score" [sortable]="true">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div>{{formatScoreColumn(dataItem.appReliabilityScore)}}</div>
                        </ng-template>
                     </kendo-grid-column>
                     <kendo-grid-column field="workFromAnywhereScore" title="Work From Anywhere Score" [sortable]="true">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div>{{formatScoreColumn(dataItem.workFromAnywhereScore)}}</div>
                        </ng-template>
                     </kendo-grid-column>
                     <kendo-grid-column field="meanResourceSpikeTimeScore" title="Mean Resource Spike Time Score" [sortable]="true">
                     <ng-template kendoGridCellTemplate let-dataItem>
                         <div>{{formatScoreColumn(dataItem.meanResourceSpikeTimeScore)}}</div>
                     </ng-template>
                  </kendo-grid-column>
                     <kendo-grid-column field="batteryHealthScore" title="Battery Health Score" [sortable]="true">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div>{{formatScoreColumn(dataItem.batteryHealthScore)}}</div>
                        </ng-template>
                     </kendo-grid-column>
                     <kendo-grid-column field="healthStatus" title="Health Status" [sortable]="true">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="truncate flex items-center gap-1">
                                <mat-icon
                                    class="text-red-500"
                                    *ngIf="dataItem.healthStatus === 'needsAttention'"
                                    [svgIcon]="'heroicons_solid:exclamation'"
                                />
                                <mat-icon
                                    class="text-green-500"
                                    *ngIf="dataItem.healthStatus === 'meetingGoals'"
                                    [svgIcon]="'heroicons_solid:check-circle'"
                                />
                                <mat-icon
                                    *ngIf="dataItem.healthStatus === 'unknown'"
                                    [svgIcon]="'heroicons_solid:information-circle'"
                                />
                                <mat-icon
                                    class="text-yellow-500"
                                    *ngIf="dataItem.healthStatus === 'insufficientData'"
                                    [svgIcon]="'heroicons_solid:information-circle'"
                                />
                                <mat-icon
                                    *ngIf="dataItem.healthStatus === 'unknownFutureValue'"
                                    [svgIcon]="'heroicons_solid:information-circle'"
                                />
                            
                               <div>{{ DeviceFieldMappings.HealthStatus[dataItem.healthStatus ]}}</div>
                            </div>    
                        </ng-template>
                     </kendo-grid-column>
                     <kendo-grid-command-column title="Actions" [width]="200">
                         <ng-template kendoGridCellTemplate let-dataItem>
                             <div class="action-buttons">
                                 <button
                                 class="min-w-10 h-7 px-2 leading-6"
                                 style="margin-left: 5px;"
                                 mat-stroked-button
                                 [matMenuTriggerFor]="summaryMenu">
                                 <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:arrow_drop_down'"></mat-icon>
                             </button>
                             <mat-menu #summaryMenu="matMenu">
                                 <button mat-menu-item (click)="onUserExp(dataItem.id)">User Experience</button>
                             </mat-menu>
                             </div>
                         </ng-template>
                     </kendo-grid-command-column>            
                    </kendo-grid>
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>

    </div>

</div>
