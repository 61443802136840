import { property } from "lodash";

export interface Device
{
    id: string;
    deviceStatus?: DeviceStatus;
    serialNumber: string;
    deviceName: string;
    manufacturer: string;
    assignedTo: any;
    assignedToPlain: string;
    upn: string;
    skuNumber: string | null;
    model: string | null;
    enrollmentState: string | null;
    po: string;
    assetTag: string;
    registrationEpoch: number;
    groupsAssigned: string;
    groupsToBeAssigned: string;
    profileName: string;
    enrollmentType: string;
    groupsDifferPersona: Boolean;
    optionAssigned: string;
    optionAssignTo: string;
    sccmAdminAccount: string;
    sccmCollection: string;
    nicheApps: string[];
    readyToShipEpoch: number;
    nicheAppAssignmentStatus: string;
    nicheAppAssignmentMessage: string;
    targetDevice: any;
}

export interface DevicePagination
{
    length: number;
    size: number;
    page: number;
    lastPage: number;
    startIndex: number;
    endIndex: number;
}


export interface AutoPilotRowCommand
{
    id: string;
    displayName: string;
    iconName: string;
    confirmationMessage: string;
    confirmationType: null | 'askDeviceName' | '';
    type: string;
    apiRoute: string;
    payload: any;
}

export enum DeviceStatus
{
    UNKNOWN = 0,
    REGISTERED = 1,
    ASSIGNED = 2,
    ENROLLED = 3,
    UPDATING = 4,
    SCCM = 5
}

export interface SccmCollection
{
    name: string;
    value: string;
    desc: string;
    subType: string;
    adminAccounts: string[];
}


export interface NicheAppCollection
{
    id: string;
    displayName: string;
    groupAssignment?: string;
    version?: string;
    type: string;
    selected: boolean;
}



