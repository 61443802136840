import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'app/core/user/user.service';

@Component({
  selector: 'app-daflexible-button',
  templateUrl: './daflexible-button.component.html',
  styleUrls: ['./daflexible-button.component.scss']
})


export class DAFlexibleButtonComponent {

  // Inputs

  @Input() color: string = 'primary';

  @Input() disabled: boolean = false;

  @Input() isRefreshing: boolean = false;

  @Output() onclick = new EventEmitter<Event>();

  constructor(public userService: UserService) {}

  // Compute the final disabled state combining custom and Auth Service conditions

  get isDisabled(): boolean {

    return this.disabled || this.userService.isUserReadOnly;
    
  }

  // Handle click events
  handleClick(event: Event): void {

    if (!this.isDisabled) {

      this.onclick.emit(event);

    }

  }

}